import {
  AppBar,
  Container,
  LinearProgress,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { FC, ReactElement } from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { useSelector } from 'react-redux';
import { StoreInterfaces } from '../types/store/StoreInterfaces';
import { Navigate, useNavigate } from 'react-router-dom';

const Menu: FC = (): ReactElement => {
  const loading = useSelector((state: StoreInterfaces) => state.site.loading);
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <SchoolIcon
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            onClick={(event) => {
              navigate('/');
            }}
          />

          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            AiBERTa
          </Typography>

          <nav>
            <Link
              variant={'button'}
              href={'/stats'}
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
            >
              Stats
            </Link>
            <Link
              variant={'button'}
              href={'/perplexity'}
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
            >
              Perplexity
            </Link>
            <Link
              variant={'button'}
              href={'/samples'}
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
            >
              Samples
            </Link>
            <Link
              variant={'button'}
              href={'/system'}
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
            >
              System
            </Link>
            <Link
              variant={'button'}
              href={'/about'}
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
            >
              About
            </Link>
          </nav>
        </Toolbar>
      </Container>
      {loading && <LinearProgress color="secondary" />}
    </AppBar>
  );
};

export default Menu;
