import axios from "axios";
import { LastStats } from "../../types/stats";

export const getLastStats = async () => {
  return await axios
    .get<LastStats[]>(
      `${process.env.REACT_APP_BACKEND_URL}/stats/last-stats`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};
