import React, { useEffect, useState } from 'react';
import { useTranslationHook } from '../../hooks/useTranslationHook/useTranslationHook';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tab,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { setLoadingValue } from '../../store/Site';
import { useDispatch } from 'react-redux';
import { getPagePhrasePerplexity } from '../../api/samples';
import { PagePhrasePerplexity } from '../../types/perplexity';
import { getLastStats } from '../../api/stats';
import { LastStats } from '../../types/stats';

interface TabPanelProps {
  index: number;
  tabValue: number;
}

const Sentense = (props: TabPanelProps) => {
  const { tabValue, index, ...other } = props;
  const { t } = useTranslationHook();
  const dispatch = useDispatch();
  const [pagePhrasePerplexity, setPagePhrasePerplexity] = useState<
    PagePhrasePerplexity[]
  >([]);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState<number[]>([0, 2000]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statsReaded, setStatsReaded] = useState<LastStats[] | undefined>(
    undefined
  );

  const SHOWSTATS: string[] = [
    'preplexity.phrase.processed.tokens',
    'preplexity.phrase.processed.lines',
    'preplexity.phrase.5000.tokens',
    'preplexity.phrase.5000.lines',
    'preplexity.phrase.4000.tokens',
    'preplexity.phrase.4000.lines',
    'preplexity.phrase.3000.tokens',
    'preplexity.phrase.3000.lines',
    'preplexity.phrase.2000.tokens',
    'preplexity.phrase.2000.lines',
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - pagePhrasePerplexity.length)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchPagePhrasePerplexity = () => {
    getPagePhrasePerplexity(value[0], value[1], 100)
      .then((response) => {
        setPagePhrasePerplexity(response);
        dispatch(setLoadingValue(false));
      })
      .catch((error) => {
        dispatch(setLoadingValue(false));
      });
  };

  const fetchStats = () => {
    try {
      getLastStats()
        .then((response) => {
          setStatsReaded(response);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const updateStats = () => {
    dispatch(setLoadingValue(true));
    fetchPagePhrasePerplexity();
    fetchStats();
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    if (pagePhrasePerplexity) dispatch(setLoadingValue(false));
  }, [pagePhrasePerplexity]);

  useEffect(() => {
    if (tabValue === index) updateStats();
  }, [tabValue, index]);

  return tabValue === index ? (
    <Container sx={{ marginTop: 5 }} hidden={tabValue !== index}>
      <Grid container alignItems='stretch' spacing={2}>
        <Grid className='left-pane' item md={4} xs={12}>
          <Container sx={{ marginBottom: 2 }}>
            <Slider
              getAriaLabel={() => 'Perplexity range'}
              value={value}
              onChange={handleChange}
              valueLabelDisplay='auto'
              min={0}
              max={20000}
              onChangeCommitted={() => updateStats()}
            />
          </Container>
          <Container>
            {statsReaded && (
              <TableContainer component={Paper}>
                <Table sx={{}} aria-label='Statistics'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Stat Name</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statsReaded
                      .filter((item) => {
                        return SHOWSTATS.includes(item.name);
                      })
                      .map((stat, key) => {
                        return (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component='th' scope='row'>
                              {t('stats.' + stat.name)}
                            </TableCell>
                            <TableCell align='right'>
                              {Intl.NumberFormat().format(stat.value)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Container>
        </Grid>
        <Grid className='right-pane' item md={8} xs={12}>
          {pagePhrasePerplexity.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label='Top Vocabulary'>
                <TableHead>
                  <TableRow>
                    <TableCell>Phrase</TableCell>
                    <TableCell>Perplexity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? pagePhrasePerplexity.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : pagePhrasePerplexity
                  ).map((token, key) => {
                    return (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {token.phrase}
                        </TableCell>
                        <TableCell align='right'>
                          {Intl.NumberFormat().format(token.ppl)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={pagePhrasePerplexity.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  ) : (
    <></>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default Sentense;
