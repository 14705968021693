import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User';
import siteReducer from './Site';

export default configureStore({
  reducer: {
    user: userReducer,
    site: siteReducer,
  },
});
