import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import LayoutRoutes from './LayoutRoutes';
import Stats from '../pages/Stats';
import Samples from '../pages/Samples';
import System from '../pages/System';
import Perplexity from '../pages/Perplexity';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/perplexity" element={<Perplexity />} />
        <Route path="/samples" element={<Samples />} />
        <Route path="/system" element={<System />} />
        <Route path="/about" element={<About />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
