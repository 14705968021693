import axios from "axios"
import { TopVocabulary } from "../../types/samples";

export const getTopVocabulary = async (count?: number) => {
    return await axios.get<TopVocabulary[]>(`${process.env.REACT_APP_BACKEND_URL}/samples/top-vocabulary`, {
        params: {
            count
        },
    }).then((response) => {
        return response.data;
    });

}