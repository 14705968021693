import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslationHook } from '../../hooks/useTranslationHook/useTranslationHook';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { setLoadingValue } from '../../store/Site';
import { useDispatch } from 'react-redux';
import { getLazyQueries } from '../../api/system/getLazyQueries';
import { DBConnection, LazyQuery } from '../../types/system';
import { getDBConnections } from '../../api/system/getDBConnections';

const System: FC = (): ReactElement => {
  const { t } = useTranslationHook();
  const dispatch = useDispatch();
  const [statsDate, setStatsDate] = useState<string>(
    new Date().toLocaleString(),
  );
  const [lazyQueriesLoaded, setLazyQueriesLoaded] = useState<boolean>(false);
  const [dbConnectionsLoaded, setDbConnectionsLoaded] =
    useState<boolean>(false);

  const [lazyQueriesData, setLazyQueriesData] = useState<LazyQuery[]>([]);
  const [dbConnectionsData, setDBConnectionsData] = useState<DBConnection[]>(
    [],
  );
  const [activeConnections, setActiveConnections] = useState<number>(0);

  const processLazyQueries = () => {
    try {
      getLazyQueries(100)
        .then((response) => {
          setLazyQueriesData(response);
          setLazyQueriesLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setLazyQueriesLoaded(true);
        });
    } catch (error) {
      console.log(error);
      setLazyQueriesLoaded(true);
    }
  };

  const processDBConnections = () => {
    try {
      getDBConnections()
        .then((response) => {
          let _activeConnections: number = 0;
          response.forEach(
            (item) =>
              (_activeConnections += Number.parseInt(item.counter.toString())),
          );
          setActiveConnections(_activeConnections);
          setDBConnectionsData(response);
          setDbConnectionsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setDbConnectionsLoaded(true);
        });
    } catch (error) {
      console.log(error);
      setDbConnectionsLoaded(true);
    }
  };

  const updateStats = () => {
    setStatsDate(new Date().toLocaleString());
    setLazyQueriesLoaded(false);
    setDbConnectionsLoaded(false);
    dispatch(setLoadingValue(true));
    processLazyQueries();
    processDBConnections();
  };

  useEffect(() => {
    if (lazyQueriesLoaded && dbConnectionsLoaded) {
      dispatch(setLoadingValue(false));
    }
  }, [lazyQueriesLoaded, dbConnectionsLoaded]);

  useEffect(() => {
    updateStats();
  }, []);

  return (
    <>
      <h1>
        Stats from: {statsDate}{' '}
        <SyncIcon color="primary" onClick={() => updateStats()}></SyncIcon>
      </h1>
      <Container>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item xs={6}>
            <Container>
              <h2>Total lazy queries: {lazyQueriesData.length}</h2>
            </Container>
            {lazyQueriesData && lazyQueriesData.length > 0 && (
              <Container>
                <TableContainer component={Paper}>
                  <Table aria-label="Vocabulary Usage">
                    <TableHead>
                      <TableRow>
                        <TableCell>Client Address</TableCell>
                        <TableCell>Query Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lazyQueriesData.map((item, key) => {
                        return (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {item.client_addr}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.query_time.milliseconds}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>
          <Grid item xs={6}>
            {dbConnectionsData && dbConnectionsData.length > 0 && (
              <Container>
                <h2>Total connections: {activeConnections}</h2>
                <TableContainer component={Paper}>
                  <Table aria-label="Vocabulary Usage">
                    <TableHead>
                      <TableRow>
                        <TableCell>Client Address</TableCell>
                        <TableCell>Connections</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dbConnectionsData.map((item, key) => {
                        return (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {item.client_addr}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.counter}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default System;
