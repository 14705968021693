import axios from "axios"
import { DBConnection } from "../../types/system";

export const getDBConnections = async () => {
    return await axios.get<DBConnection[]>(`${process.env.REACT_APP_BACKEND_URL}/system/db-connections`, {
        params: {
        },
    }).then((response) => {
        return response.data;
    });

}