import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SiteState } from '../interfaces/store/SiteState';

const initialState: SiteState = {
  loading: false,
};

const site = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoadingValue } = site.actions;

export default site.reducer;
