import { Box } from "@mui/material";
import { ReactNode } from "react";

interface ContentProps {
  children: ReactNode;
}

const Content = ({ children }: ContentProps) => {
  return (
    <Box>
      {children}
    </Box>
  );
};

export default Content;
