import axios from "axios";
import { PagePhrasePerplexity } from "../../types/perplexity";

export const getPagePhrasePerplexity = async (
  minPerplexity?: number,
  maxPerplexity?: number,
  count?: number
) => {
  return await axios
    .get<PagePhrasePerplexity[]>(
      `${process.env.REACT_APP_BACKEND_URL}/samples/page-phrase-perplexity`,
      {
        params: {
          minPerplexity,
          maxPerplexity,
          count,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};
