import React from "react";
import "./App.css";
import AppRoutes from "../routes"
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
