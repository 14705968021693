import { Container } from "@mui/material";
import { ReactNode } from "react";
import Menu from "./Menu";
import Content from "./Content";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Container maxWidth="lg">
      <div className="my-4">
        <Menu></Menu>
        <Content>{children}</Content>
      </div>
    </Container>
  );
};

export default Layout;
