import { FC, ReactElement } from "react";
import WhoCard from "../../components/WhoCard";

const About: FC = (): ReactElement => {
  return (
    <>
      <WhoCard />
    </>
  );
};

export default About;
