import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, Button } from '@mui/material'

const WhoCard = () => {

    return (<Card sx={{ maxWidth: 345, marginTop: 2 }}>
        <CardMedia
          sx={{ height: 250 }}
          image="/images/NM_Foto.jpg"
          title="Nuno Miquelina"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Nuno Miquelina
          </Typography>
          <Typography variant="body1" color="text.secondary">
            PhD Student
          </Typography>
          <Typography variant="caption" color="text.secondary">
            nuno.miquelina@gmail.com
          </Typography>
        </CardContent>
      </Card>);

};

export default WhoCard;