import axios from "axios"
import { LazyQuery } from "../../types/system";

export const getLazyQueries = async (duration?: number) => {
    return await axios.get<LazyQuery[]>(`${process.env.REACT_APP_BACKEND_URL}/system/lazy-queries`, {
        params: {
            duration: duration? duration : 100
        },
    }).then((response) => {
        return response.data;
    });

}