import axios from "axios"
import { TopVocabularyUsage } from "../../types/samples";

export const getTopVocabularyUsage = async (vocabulary_id:number, count?: number) => {
    return await axios.get<TopVocabularyUsage[]>(`${process.env.REACT_APP_BACKEND_URL}/samples/top-vocabulary-usage`, {
        params: {
            vocabulary_id,
            count
        },
    }).then((response) => {
        return response.data;
    });

}