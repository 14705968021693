import React, { FC, ReactElement } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import Sentense from './Sentense';
import Document from './Document';

const Perplexity: FC = (): ReactElement => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `perplexity-tab-${index}`,
      'aria-controls': `perplexity-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label='perplexity tabs'
      >
        <Tab label='Sentense' {...a11yProps(0)} />
        <Tab label='Document' {...a11yProps(1)} />
      </Tabs>
      <Sentense tabValue={tabValue} index={0}></Sentense>
      <Document tabValue={tabValue} index={1}></Document>
    </Box>
  );
};

export default Perplexity;
