import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactElement } from "react";

const Home: FC = (): ReactElement => {
  return (
    <>
      <Box sx={{
        padding: 2,
        bgcolor: 'background.paper',
      }}>
        <Typography variant="h1" align="center" className="pt-1">
          AiBERTa - Portuguese-European BERT based model
        </Typography>
        <Box sx={{
          padding: 2,
          paddingTop: 3
        }}>
          <Typography className="text-4xl">
            Building a language model from free available internet information
            takes several steps and challenges. This new model aims to be a
            BERT-based language model for Portuguese-European, with no specific
            context. The corpus was built using a web page archive infrastructure
            provided by Arquivo.pt and restricted to .pt domains. This paper will
            describe the overall process of building the corpus and training a
            BERT model.
          </Typography>
        </Box>

      </Box>
    </>
  );
};

export default Home;
