import React, { FC, ReactElement, useEffect, useState } from 'react';
import { LastStats } from '../../types/stats';
import { getLastStats } from '../../api/stats/getLastStats';
import { useTranslationHook } from '../../hooks/useTranslationHook/useTranslationHook';
import { getMetricStat } from '../../api/stats';
import { Line } from 'react-chartjs-2';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { setLoadingValue } from '../../store/Site';
import { useDispatch } from 'react-redux';

const Stats: FC = (): ReactElement => {
  const { t } = useTranslationHook();
  const dispatch = useDispatch();
  const [statsReaded, setStatsReaded] = useState<LastStats[] | undefined>(
    undefined
  );
  const [statsReadedCounter, setStatsReadedCounter] = useState<number>(0);
  const [
    statsMetricPageTextUnprocessedReaded,
    SetstatsMetricPageTextUnprocessedReaded,
  ] = useState<LastStats[] | undefined>(undefined);
  const [
    statsMetricPageTextProcessedReaded,
    SetstatsMetricPageTextProcessedReaded,
  ] = useState<LastStats[] | undefined>(undefined);
  const [
    statsMetricPageTextCounterReaded,
    SetstatsMetricPageTextCounterReaded,
  ] = useState<LastStats[] | undefined>(undefined);
  const [chartPageTextData, setChartPageTextData] = useState<any | undefined>(
    undefined
  );

  const [
    statsMetricLinksUnprocessedReaded,
    SetStatsMetricLinksUnprocessedReaded,
  ] = useState<LastStats[] | undefined>(undefined);
  const [statsMetricLinksProcessedReaded, SetStatsMetricLinksProcessedReaded] =
    useState<LastStats[] | undefined>(undefined);
  const [statsMetricLinksCounterReaded, SetStatsMetricLinksCounterReaded] =
    useState<LastStats[] | undefined>(undefined);
  const [chartLinksData, setChartLinksData] = useState<any | undefined>(
    undefined
  );

  const [statsMetricTokensReaded, SetStatsMetricTokensReaded] = useState<
    LastStats[] | undefined
  >(undefined);
  const [chartTokensData, setChartTokensData] = useState<any | undefined>(
    undefined
  );

  const HIDESTATS: string[] = [
    'preplexity.phrase.5000.tokens',
    'preplexity.phrase.5000.lines',
    'preplexity.phrase.4000.tokens',
    'preplexity.phrase.4000.lines',
    'preplexity.phrase.3000.tokens',
    'preplexity.phrase.3000.lines',
    'preplexity.doc.5000.tokens',
    'preplexity.doc.5000.docs',
    'preplexity.doc.4000.tokens',
    'preplexity.doc.4000.docs',
    'preplexity.doc.3000.tokens',
    'preplexity.doc.3000.docs',
  ];

  const fetchMetricCounter = (metric: string, setter: any) => {
    try {
      getMetricStat(metric, 'desc')
        .then((response) => {
          setStatsReadedCounter(statsReadedCounter + 1);
          setter(response);
        })
        .catch((error) => {
          setStatsReadedCounter(statsReadedCounter + 1);
        });
    } catch (error) {
      setStatsReadedCounter(statsReadedCounter + 1);
    }
  };

  const fetchMetricPageTextCounter = () => {
    try {
      getMetricStat('page-text.counter', 'desc')
        .then((response) => {
          SetstatsMetricPageTextCounterReaded(response);
        })
        .catch((error) => {
          setStatsReadedCounter(statsReadedCounter + 1);
        });
    } catch (error) {
      setStatsReadedCounter(statsReadedCounter + 1);
    }
  };

  const fetchMetricPageTextUnprocessed = () => {
    try {
      getMetricStat('page-text.unprocessed', 'desc')
        .then((response) => {
          setStatsReadedCounter(statsReadedCounter + 1);
          SetstatsMetricPageTextUnprocessedReaded(response);
        })
        .catch((error) => {
          setStatsReadedCounter(statsReadedCounter + 1);
        });
    } catch (error) {
      setStatsReadedCounter(statsReadedCounter + 1);
    }
  };

  const fetchMetricPageTextProcessed = () => {
    try {
      getMetricStat('page-text.processed', 'desc')
        .then((response) => {
          setStatsReadedCounter(statsReadedCounter + 1);
          SetstatsMetricPageTextProcessedReaded(response);
        })
        .catch((error) => {
          setStatsReadedCounter(statsReadedCounter + 1);
        });
    } catch (error) {
      setStatsReadedCounter(statsReadedCounter + 1);
    }
  };

  const fetchStats = () => {
    try {
      getLastStats()
        .then((response) => {
          setStatsReadedCounter(statsReadedCounter + 1);
          setStatsReaded(response);
        })
        .catch((error) => {
          setStatsReadedCounter(statsReadedCounter + 1);
        });
    } catch (error) {
      setStatsReadedCounter(statsReadedCounter + 1);
    }
  };

  useEffect(() => {
    if (
      !statsMetricPageTextUnprocessedReaded ||
      !statsMetricPageTextProcessedReaded ||
      !statsMetricPageTextCounterReaded
    )
      return;
    let labelSet = [...statsMetricPageTextUnprocessedReaded].reverse();
    setChartPageTextData({
      labels: labelSet.map((row) => new Date(row.run_at).toLocaleString()),
      datasets: [
        {
          label: 'Total',
          data: statsMetricPageTextCounterReaded
            .reverse()
            .map((row) => row.value),
        },
        {
          label: 'To Process',
          data: statsMetricPageTextUnprocessedReaded
            .reverse()
            .map((row) => row.value),
        },
        {
          label: 'Processed',
          data: statsMetricPageTextProcessedReaded
            .reverse()
            .map((row) => row.value),
        },
      ],
    });
  }, [
    statsMetricPageTextUnprocessedReaded,
    statsMetricPageTextProcessedReaded,
    statsMetricPageTextCounterReaded,
  ]);

  useEffect(() => {
    if (
      !statsMetricLinksUnprocessedReaded ||
      !statsMetricLinksProcessedReaded ||
      !statsMetricLinksCounterReaded
    )
      return;
    let labelSet = [...statsMetricLinksUnprocessedReaded].reverse();
    setChartLinksData({
      labels: labelSet.map((row) => new Date(row.run_at).toLocaleString()),
      datasets: [
        {
          label: 'Total',
          data: statsMetricLinksCounterReaded.reverse().map((row) => row.value),
        },
        {
          label: 'To Process',
          data: statsMetricLinksUnprocessedReaded
            .reverse()
            .map((row) => row.value),
        },
        {
          label: 'Processed',
          data: statsMetricLinksProcessedReaded
            .reverse()
            .map((row) => row.value),
        },
      ],
    });
  }, [
    statsMetricLinksUnprocessedReaded,
    statsMetricLinksProcessedReaded,
    statsMetricLinksCounterReaded,
  ]);

  useEffect(() => {
    if (!statsMetricTokensReaded) return;
    let labelSet = [...statsMetricTokensReaded].reverse();
    setChartTokensData({
      labels: labelSet.map((row) => new Date(row.run_at).toLocaleString()),
      datasets: [
        {
          label: 'Total',
          data: statsMetricTokensReaded.reverse().map((row) => row.value),
        },
      ],
    });
  }, [statsMetricTokensReaded]);

  const updateStats = () => {
    dispatch(setLoadingValue(true));
    setChartLinksData(undefined);
    setChartPageTextData(undefined);
    setStatsReadedCounter(0);
    setStatsReaded(undefined);
    SetStatsMetricLinksCounterReaded(undefined);
    SetStatsMetricLinksProcessedReaded(undefined);
    SetStatsMetricLinksUnprocessedReaded(undefined);
    SetstatsMetricPageTextCounterReaded(undefined);
    SetstatsMetricPageTextProcessedReaded(undefined);
    SetstatsMetricPageTextUnprocessedReaded(undefined);
    fetchStats();
    fetchMetricPageTextUnprocessed();
    fetchMetricPageTextProcessed();
    fetchMetricPageTextCounter();
    fetchMetricCounter('cdxj-url2.total', SetStatsMetricLinksCounterReaded);
    fetchMetricCounter(
      'cdxj-url2.unprocessed',
      SetStatsMetricLinksUnprocessedReaded
    );
    fetchMetricCounter(
      'cdxj-url2.processed',
      SetStatsMetricLinksProcessedReaded
    );
    fetchMetricCounter('page-phrase.tokens', SetStatsMetricTokensReaded);
  };

  useEffect(() => {
    if (statsReadedCounter >= 6) return;
    dispatch(setLoadingValue(false));
  }, [statsReadedCounter]);

  useEffect(() => {
    updateStats();
  }, []);

  return (
    <>
      {statsReaded && statsReaded.length > 0 && (
        <h1>
          Stats from: {new Date(statsReaded[0].run_at).toLocaleString()}{' '}
          <SyncIcon color='primary' onClick={() => updateStats()}></SyncIcon>
        </h1>
      )}
      <Container>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item xs={6}>
            {chartPageTextData && (
              <>
                <Typography variant='h6' align='center' paddingBottom={2}>
                  Page Text Processing
                </Typography>
                <div style={{ height: '350px' }}>
                  <Line data={chartPageTextData}></Line>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {chartLinksData && (
              <>
                <Typography variant='h6' align='center' paddingBottom={2}>
                  Links Processing
                </Typography>

                <div style={{ height: '350px' }}>
                  <Line data={chartLinksData}></Line>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item xs={6}>
            {chartTokensData && (
              <>
                <Typography variant='h6' align='center' paddingBottom={2}>
                  Tokens
                </Typography>

                <div style={{ height: '350px' }}>
                  <Line data={chartTokensData}></Line>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {statsReaded && (
              <TableContainer component={Paper}>
                <Table sx={{}} aria-label='Statistics'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Stat Name</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statsReaded
                      .filter((item) => {
                        return !HIDESTATS.includes(item.name);
                      })
                      .map((stat, key) => {
                        return (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component='th' scope='row'>
                              {t('stats.' + stat.name)}
                            </TableCell>
                            <TableCell align='right'>
                              {Intl.NumberFormat().format(stat.value)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Stats;
