import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../interfaces/store/UserState';

const initialState: UserState = {
  user: localStorage.getItem('user') || null,
  token: localStorage.getItem('auth') || null,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {},
    setLoggedOut: (state, action) => {},
  },
});

export const { setLoggedIn, setLoggedOut } = user.actions;

export default user.reducer;
