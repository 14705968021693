import axios from "axios";
import { LastStats } from "../../types/stats";

export const getMetricStat = async (metric: string, order?: string) => {
  return await axios
    .get<LastStats[]>(
      `${process.env.REACT_APP_BACKEND_URL}/stats/metric-stat`,
      {
        params: {
          metric: metric,
          order: order,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};
